<div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
    <h5 class="text-3xl font-bold leading-normal text-gray-800" id="exampleModalScrollableLabel">
        Cambiar contraseña
    </h5>
</div>
<div class="flex flex-col modal-body relative p-4 text-center">
    <form [formGroup]="PasswordForm">
        <mat-form-field class="w-full">
            <mat-label>Contraseña actual</mat-label>
            <input id="password" matInput type="password" [formControlName]="'password'" #passwordField>
            <button mat-icon-button type="button"
                (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                matSuffix>
                <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Contraseña nueva</mat-label>
            <input id="password2" matInput type="password" [formControlName]="'password2'" #passwordField2>
            <button mat-icon-button type="button"
                (click)="passwordField2.type === 'password' ? passwordField2.type = 'text' : passwordField2.type = 'password'"
                matSuffix>
                <mat-icon class="icon-size-5" *ngIf="passwordField2.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                <mat-icon class="icon-size-5" *ngIf="passwordField2.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Confirmar contraseña nueva</mat-label>
            <input id="password3" matInput type="password" [formControlName]="'password3'" #passwordField3>
            <button mat-icon-button type="button"
                (click)="passwordField3.type === 'password' ? passwordField3.type = 'text' : passwordField3.type = 'password'"
                matSuffix>
                <mat-icon class="icon-size-5" *ngIf="passwordField3.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                <mat-icon class="icon-size-5" *ngIf="passwordField3.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
            </button>
        </mat-form-field>
    </form>
</div>
<!-- <div class="modal-body relative p-4 text-center">
    <a class="underline decoration-primary-500 text-primary text-xl font-semibold cursor-pointer">Descarga comprobante
        de pago</a>
</div> -->

<div class="flex items-end w-full content-end justify-end sm:justify-end space-x-3 ng-star-inserted">
    <button mat-flat-button class="mat-focus-indicator mat-flat-button mat-button-base text-white ng-star-inserted"
        type="button" style="background-color: red !important;" (click)="cerrar()">
        Cancelar
    </button>
    <button mat-flat-button
        class="mat-focus-indicator mat-flat-button mat-button-base bg-green-500 hover:bg-green-600 text-white ng-star-inserted"
        type="button" (click)="preCambiar()">
        Guardar
    </button>
</div>