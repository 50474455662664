import { Injectable, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { IProfileDto } from './Models/profile';
import { saveAs } from '@progress/kendo-file-saver';

@Injectable({
  providedIn: 'root'
})
export class SharedService implements OnInit {
  cargando: boolean = false;
  datosUser: IProfileDto = null;
  resetPassObj: any = null;
  constructor(
  ) { }

  async ngOnInit() {

  }
  isNullOrUndefined(valor, validarVacios: boolean = false) {
    return (valor === null || valor === undefined || (validarVacios && (typeof valor === 'string' && valor.trim() === "")));
  }
  removeTildes(text: string) {
    text = text.replace("á", "a");
    text = text.replace("Á", "A");
    text = text.replace("é", "e");
    text = text.replace("É", "E");
    text = text.replace("í", "i");
    text = text.replace("Í", "I");
    text = text.replace("ó", "o");
    text = text.replace("Ó", "O");
    text = text.replace("ú", "u");
    text = text.replace("Ú", "U");
    return text;
  }

  downLoadFileFactura(data: any, type: string, terminacion: string, nombre: string) {
    // this.nombreUsuario = this._Auth0Service.usuarioNamePagina();
    const byteString = window.atob(data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([int8Array], { type: type.toString() });
    // var url = window.URL.createObjectURL(blob);

    saveAs(blob, 'Factura_' + nombre + terminacion);
    // window.open(url);
  }
}
