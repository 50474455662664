import { Component } from '@angular/core';
import { SharedService } from './services/shared.service';
import { environment } from 'environments/environment';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

//declare var OpenPay:any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        public _shared: SharedService,
        private router: Router,
        private _auth: AuthService,
    ) {


        //    debugger;
        /* OpenPay.setId(environment.openPay.merchantId );
         OpenPay.setApiKey(environment.openPay.apiKey);
         OpenPay.setSandboxMode(true);
         let token=OpenPay.token.create({
             "card_number":"4111111111111111",
             "holder_name":"Juan Perez Ramirez",
             "expiration_year":"24",
             "expiration_month":"12",
             "cvv2":"110",

       }, (par)=>{
         debugger;
         console.log(par);
       }, (error)=>{
         debugger;
       });

         console.log(OpenPay);
         console.log(token);*/
        _auth.isAuthenticated().subscribe({
            next: data => {
                let canc = localStorage.getItem("fcancel");
                if (canc !== null) {
                    this.router.navigate(["/cancel"]);
                }
            }, error: data => {
                console.log(data);
            }
        });
    }
}
