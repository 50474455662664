import { Injectable, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

declare var OpenPay: any;
@Injectable({
  providedIn: 'root',
})
export class OpenPayService implements OnInit {
  // List of error codes and their corresponding messages
  private errorCodeList = [
    { code: 1000, status: 500, message: "Ocurrió un error en el servidor interno de Openpay." },
    { code: 1001, status: 400, message: "La solicitud no tiene un formato JSON válido, los campos no tienen el formato correcto o la solicitud no tiene los campos obligatorios." },
    { code: 1002, status: 401, message: "La solicitud no está autenticada o es incorrecta." },
    { code: 1003, status: 422, message: "La operación no se pudo procesar porque uno o más parámetros son incorrectos." },
    { code: 1004, status: 503, message: "Un servicio requerido no está disponible." },
    { code: 1005, status: 404, message: "El recurso requerido no existe." },
    { code: 1006, status: 409, message: "Ya existe una transacción con el mismo pedido ID." },
    { code: 1008, status: 423, message: "Una de las cuentas requeridas está desactivada." },
    { code: 1007, status: 402, message: "La transferencia de fondos entre la cuenta bancaria o tarjeta y la cuenta Openpay fue rechazada." },
    { code: 1009, status: 413, message: "El cuerpo de la solicitud es demasiado grande." },
    { code: 1010, status: 403, message: "La clave pública se está utilizando para realizar una solicitud que requiere la clave privada, o la clave privada se está utilizando desde Javascript." },
    { code: 1011, status: 404, message: "El recurso fue eliminado previamente." },
    { code: 1012, status: 412, message: "El monto de la transacción está fuera de los límites." },
    { code: 1013, status: 412, message: "La operación no está permitida en el recurso." },
    { code: 1014, status: 401, message: "La cuenta está inactiva." },
    { code: 1015, status: 504, message: "No se pudo obtener ninguna respuesta de la puerta de enlace." },
    { code: 1016, status: 409, message: "El correo electrónico del comerciante ya ha sido procesado." },
    { code: 1017, status: 502, message: "La pasarela de pago no está disponible en este momento, inténtalo de nuevo más tarde." },
    { code: 1018, status: 402, message: "El número de reintentos de carga es mayor al permitido." },
    { code: 1020, status: 400, message: "El número de dígitos decimales no es válido para esta moneda." },
    { code: 2001, status: 409, message: "La cuenta bancaria ya existe." },
    { code: 2003, status: 409, message: "The external_id already exists." },
    { code: 2004, status: 422, message: "El número de la tarjeta es inválido." },
    { code: 2005, status: 400, message: "La fecha de vencimiento a expirado." },
    { code: 2006, status: 400, message: "El código de seguridad (CVV2) es requerido." },
    { code: 2007, status: 412, message: "El número de tarjeta solamente es válido en modo sandbox." },
    { code: 2008, status: 412, message: "La tarjeta no es válida para puntos." },
    { code: 2009, status: 412, message: "El código de seguridad (CVV2) es inválido." },
    { code: 2010, status: 402, message: "Error de autenticación 3D Secure." },
    { code: 2011, status: 422, message: "Tipo de producto de tarjeta no compatible." },
    { code: 3001, status: 402, message: "La tarjeta fue declinada por el banco." },
    { code: 3002, status: 402, message: "La tarjeta ha caducado." },
    { code: 3003, status: 402, message: "La tarjeta no tiene fondos suficientes." },
    { code: 3004, status: 402, message: "La tarjeta fue reportada como robada." },
    { code: 3005, status: 402, message: "Riesgo de fraude detectado por el sistema antifraude." },
    { code: 3006, status: 412, message: "Solicitud no permitida." },
    { code: 3009, status: 402, message: "La tarjeta fue reportada como perdida." },
    { code: 3010, status: 402, message: "El banco ha restringido la tarjeta." },
    { code: 3011, status: 402, message: "El banco ha solicitado la retención de la tarjeta." },
    { code: 3012, status: 412, message: "Se requiere autorización bancaria para este cargo." },
    { code: 3201, status: 412, message: "Comerciante no autorizado para usar promociones." },
    { code: 3203, status: 412, message: "Promoción no válida para ese tipo de tarjeta." },
    { code: 3204, status: 412, message: "El monto de la transacción es inferior al mínimo para la promoción." },
    { code: 3205, status: 412, message: "Promoción no permitida." },
    { code: 4001, status: 412, message: "La cuenta Openpay no tiene fondos suficientes." },
    { code: 4002, status: 412, message: "La operación no se podrá completar hasta que se paguen las tarifas pendientes." },
    { code: 6001, status: 409, message: "El webhook ya ha sido procesado." },
    { code: 6002, status: 412, message: "No se pudo conectar con el servicio de webhook." },
    { code: 6003, status: 502, message: "El servicio respondió con un error." }
  ];

  constructor() {
    this.ngOnInit();
  }
  leerCodigoErrorOP(code: number): string {
    const error = this.errorCodeList.find(x => x.code === code);
    if (!error) {
      return "Error desconocido, favor de contactar al administrador del sistema.";
    }
    return `${error.code} : ${error.message} (${error.code})`;
  }

  ngOnInit(): void {
    OpenPay.setId(environment.openPay.merchantId);
    OpenPay.setApiKey(environment.openPay.apiKey);
    OpenPay.setSandboxMode(environment.openPay.modoSandbox);
  }

  payWithToken(cardNumber: string, holderName: string, expirationYear: string, expirationMonth: string, cvv: string)
    : Promise<string | undefined> {
    let prom = new Promise<string | undefined>((resolve, reject) => {
      var res = OpenPay.token.create({
        "card_number": cardNumber,
        "holder_name": holderName,
        "expiration_year": expirationYear,
        "expiration_month": expirationMonth,
        "cvv2": cvv,

      }, (result) => {
        if (result.status == 200) {
          resolve(result.data.id);
        }
        resolve(undefined);

      }, (error) => {
        reject(error);
      });
    });

    return prom;
  }

}