import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormatTel10Pipe } from './codePipes/format-tel10.pipe';

@NgModule({
    declarations: [
        FormatTel10Pipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormatTel10Pipe,
    ]
})
export class SharedModule {
}
