export const environment = {
    nombre:'AERO CON TAXI',
    production: true,
    hmr: false,
    tema:'theme-amber',
    env:'integracion',
    colorPrincipalhexa:'#FF4713',
    rutaimagenes:'assets/images/logo/aero/',
    baseUrl: 'https://ry66kit3l7.execute-api.us-west-2.amazonaws.com/Prod/api/', // Produccion
    endpointPublic: 'https://ry66kit3l7.execute-api.us-west-2.amazonaws.com/Prod/cliente',
    endpointVentaRapida: "https://ry66kit3l7.execute-api.us-west-2.amazonaws.com/Prod/venta-rapida",
    amplify: {
        Auth: {
            region: 'us-west-2',
            /* =====> PRODUCCIÓN <===== */
            userPoolId: 'us-west-2_bVCJgxepg',
            userPoolWebClientId: '5khhi0tqf778sgbl3fmau8g3ru'
            
        }
    },
    openPay:{
        apiKey: "pk_131e98e16ef441599265579e9ad12e9d",
        merchantId:"m0edznwalqbjbuwxpx6e",
        modoSandbox: false
    }
};
