/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'reservarahora',
        title: 'Reservar ahora',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/reservacion'
    },
    {
        id: 'misviajes',
        title: 'Mis viajes',
        type: 'basic',
        icon: 'heroicons_outline:paper-airplane',
        link: '/viajes'
    },
    {
        id: 'facturar',
        title: 'Facturación',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/facturar',
        externalLink: true,
        target: '_blank'
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/impuestos/perfil'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
