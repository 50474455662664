// import { CrudService } from './crud.service';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClienteService extends CrudService<any> {
  endpointPublic: string = environment.endpointPublic;

  constructor(private config: AppConfigService, http: HttpClient) {
    super(config.ClienteUrl, http);
    // console.log(this.endpoint.replace("api/v1/", ""));
    // this.endpointPublic = this.endpoint.replace("api/v1/", "");
  }
  getClientePorRFC(rfc: string) {
    let url = `${this.endpointPublic}/${rfc}`
    return this.http.get<any>(url);
  }
  GetDomicilioPorCodigoPostal(CodigoPostal) {
    let url = `${this.endpointPublic}/Domicilio/${CodigoPostal}`
    return this.http.get<any>(url);
  }
  GetTicketPorFolio(folio: string) {
    let url = `${this.endpointPublic}/Ticket/${folio}`
    return this.http.get<any>(url);
  }
  Facturar(modelo: any) {
    let url = `${this.endpointPublic}/Facturacion`
    return this.http.post<any>(url, modelo);
  }
  contactanos(modelo: any) {
    let url = `${this.endpointPublic}/contactanos`
    return this.http.post<any>(url, modelo);
  }

  activar() {
    let url = `${this.endpointPublic}/test/en`
    return this.http.get<any>(url);
  }

  reenviar(invoiceOrderID) {
    let url = `${this.endpointPublic}/reenviarfactura/${invoiceOrderID}`
    return this.http.get<any>(url);
  }
  consultaRegimenesFiscales(tipoPersona) {
    let url = `${this.endpointPublic}/regimenfiscal/list?tipoPersona=${tipoPersona}`
    return this.http.get<any>(url);
  }
  consultaUsoCFDI(reginId) {
    let url = `${this.endpointPublic}/usocfdi/list?regimenId=${reginId}`
    return this.http.get<any>(url);
  }
} 