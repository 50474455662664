import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config';
import { AppConfig, Scheme, Theme, Themes } from 'app/core/config/app.config';
import { Layout } from 'app/layout/layout.types';
import { FormBuilder, FormControl, FormGroup, NgControl, Validators, } from '@angular/forms';
import { ContactoDto } from 'app/Models/IContactanos';
import { ClienteService } from 'app/services/cliente.service';
import { SweetAlertMsgService } from 'app/services/sweet-alert-msg.service';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styles: [
    `
            settings {
                position: static;
                display: block;
                flex: none;
                width: auto;
            }
        `
  ],
  encapsulation: ViewEncapsulation.None
})

export class SettingsComponent implements OnInit, OnDestroy {
  config: AppConfig;
  layout: Layout;
  scheme: 'dark' | 'light';
  theme: string;
  themes: Themes;
  form: FormGroup;
  contacto: ContactoDto = <ContactoDto>{};

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _fuseConfigService: FuseConfigService,
    private _clienteservice: ClienteService,
    private _alert: SweetAlertMsgService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.inicializarForm();
    // Subscribe to config changes
    this._fuseConfigService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: AppConfig) => {

        // Store the config
        this.config = config;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  inicializarForm() {
    this.form = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      correo: new FormControl('', [Validators.required, Validators.email]),
      mensaje: new FormControl('', [Validators.required]),
    });
  }
  enviar() {
    if (this.form.valid) {
      this.contacto.nombre = this.form.controls['nombre'].value;
      this.contacto.correo = this.form.controls['correo'].value;
      this.contacto.mensaje = this.form.controls['mensaje'].value;
      this._clienteservice.contactanos(this.contacto).subscribe(res => {
        this._alert.alertSuccess("Éxito", "Se ha enviado exitosamente.");
        this.form.reset();
      }, error => {

        this._alert.alertInfo("Información", 'Ha ocurrido un error al enviar');
        console.log(error.error);
      });

    } else {
      this._alert.alertWarning("Atención", "El formulario presenta errores o está incompleto.")
    }
  }



  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the layout on the config
   *
   * @param layout
   */
  setLayout(layout: string): void {
    // Clear the 'layout' query param to allow layout changes
    this._router.navigate([], {
      queryParams: {
        layout: null
      },
      queryParamsHandling: 'merge'
    }).then(() => {
      layout = 'classy'
      // Set the config
      this._fuseConfigService.config = { layout };
    });
  }

  /**
   * Set the scheme on the config
   *
   * @param scheme
   */
  setScheme(scheme: Scheme): void {
    this._fuseConfigService.config = { scheme };
  }

  /**
   * Set the theme on the config
   *
   * @param theme
   */
  setTheme(theme: Theme): void {
    this._fuseConfigService.config = { theme };
  }
}
