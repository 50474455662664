<!-- Loading bar -->
<fuse-loading-bar *ngIf="_shared?.cargando"></fuse-loading-bar>

<!-- Navigation -->
<!-- bg-white es color de fondo del menú -->
<!-- bg-slate-900
bg-amber-800
 -->
<fuse-vertical-navigation
    class="dark bg-slate-600 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation?.default"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img
                    class="w-60"
                    src="assets/images/imagenesinicio/nuevoLogoBlanco.png"
                />
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!-- <notifications></notifications> -->
                <!-- <user [showAvatar]="false"></user> -->
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-[50%]">
                <!-- IMAGEN DE EJEMPLO -->
                <img
                    class="w-full h-full rounded-lg border border-4 border-slate-200"
                    [src]="
                        _shared?.datosUser?.imagenS3Link !== null &&
                        _shared?.datosUser?.imagenS3Link !== undefined &&
                        _shared?.datosUser?.imagenS3Link !== ''
                            ? _shared?.datosUser?.imagenS3Link
                            : userImage
                    "
                    alt="User avatar"
                />
                <!-- <img class="w-full h-full rounded-lg" *ngIf="user?.avatar" [src]="user?.avatar" alt="User avatar">
                <mat-icon class="icon-size-24" *ngIf="!user?.avatar" [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> -->
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium"
                >
                    {{ _shared?.datosUser?.nombre }}
                    {{ _shared?.datosUser?.apellidoPaterno }}
                    {{ _shared?.datosUser?.apellidoMaterno }}
                    <!-- {{user?.name}} -->
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-primary text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary"
                >
                    {{ _shared?.datosUser?.correo }}
                    <!-- {{user?.email}} -->
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <!-- <div class="flex flex-col pl-12 pt-12">
            <div class="flex flex-row">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-4 m-0.5 opacity-70" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                        d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
                <a class="text-lg text-white font-medium opacity-70 no-underline hover:underline"
                    [routerLink]="'/home'">Inicio</a>
            </div>

            <div class="flex flex-row mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-4 m-0.5 opacity-70" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                    <path fill-rule="evenodd"
                        d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd" />
                </svg>
                <a class="text-lg text-white font-medium opacity-70 no-underline hover:underline"
                    [routerLink]="'/reservacion'">Reservar ahora</a>
            </div>

            <div class="flex flex-row mt-2">
                <mat-icon matSuffix class="h-5 w-5 mr-2 opacity-70"
                    style="font-size: 20px !important; color: white !important">
                    directions_car</mat-icon> &nbsp;
                <a class="text-lg text-white font-medium opacity-70 no-underline hover:underline"
                    [routerLink]="'/viajes'">Mis viajes</a>
            </div>

            <div class="flex flex-row mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-4 m-0.5 opacity-70" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                        d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                    <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                        clip-rule="evenodd" />
                </svg>
                <a class="text-lg text-white font-medium opacity-70 no-underline hover:underline"
                    [routerLink]="'/wallet'">Wallet</a>
            </div>

            <div class="flex flex-row mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-4 m-0.5 opacity-70" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clip-rule="evenodd" />
                </svg>
                <a class="text-lg text-white font-medium opacity-70 no-underline hover:underline"
                    [routerLink]="'/perfil'">Mi perfil</a>
            </div>

            <div class="flex flex-row mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-4 m-0.5 opacity-70" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clip-rule="evenodd" />
                </svg>
                <a class="text-lg text-white font-medium opacity-70 no-underline hover:underline"
                    [routerLink]="'/impuestos/perfil'">Perfil de
                    impuestos</a>
            </div>

            <div class="flex flex-row mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-4 m-0.5 opacity-70" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                        clip-rule="evenodd" />
                </svg>
                <a class="text-lg text-white font-medium opacity-70 no-underline hover:underline">Cerrar
                    sesión</a>
            </div>
        </div> -->

        <!-- <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img
                class="max-w-36"
                src="assets/images/logo/logo-text-on-dark.svg">
        </div> -->
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="fixed flex flex-0 items-center w-full h-12 px-4 md:px-6 z-40 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div
            class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2 absolute right-0"
            [ngClass]="{
                'w-20 sm:w-80 md:w-80 lg:w-80 xl:w-80 2xl:w-80': !nav
            }"
        >
            <!-- Bandera del país -->
            <!-- <languages></languages>  -->

            <!-- Pantalla completa -->
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->

            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <notifications></notifications> -->
            <user></user>
            <!-- <shortcuts></shortcuts>
            <messages></messages> -->

            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
