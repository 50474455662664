import { Injectable, Injector } from '@angular/core';

const version: string = "v1/";

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  environment: any;

  get ClienteUrl(): string {
    return `${this.injector.get('BASE_URL')}cliente`;
  }
  get SessionUrl(): string {
    return `${this.injector.get("BASE_URL")}${version}Sesion`;
  }
  get viajesUrl(): string {
    return `${this.injector.get("BASE_URL")}${version}Viaje`;
  }
  get Ubicaciones(): string {
    return `${this.injector.get("BASE_URL")}${version}ubicaciones`;
  }
  get DatosFiscales(): string {
    return `${this.injector.get("BASE_URL")}${version}DatosFiscales`;
  }
  get DireccionUrl(): string {
    return `${this.injector.get("BASE_URL")}${version}Direccion`;
  }
  get ZonasUrl(): string {
    return `${this.injector.get("BASE_URL")}${version}Zonas`;
  }
  get ReservacionesUrl(): string {
    return `${this.injector.get("BASE_URL")}${version}Reservaciones`;
  }
  get TarifasUrl(): string {
    return `${this.injector.get("BASE_URL")}${version}Tarifas`;
  }
  get TipoVehiculoUrl(): string {
    return `${this.injector.get("BASE_URL")}${version}TipoVehiculo`;
  }
  get FacturacionUrl(): string {
    return `${this.injector.get("BASE_URL")}${version}Facturar`;
  }
  get ReportesUrl(): string {
    return `${this.injector.get("BASE_URL")}${version}Reportes`;
  }
  get UserClienteUrl(): string {
    return `${this.injector.get('BASE_URL')}${version}Client`;
  }
  get CancelarCuentaUrl(): string {
    return `${this.injector.get('BASE_URL')}${version}CancelarCuenta`;
  }
  get MonederoUrl(): string {
    return `${this.injector.get("BASE_URL")}${version}monedero`;
  }
  get VentaRapidaUrl(): string {
    return `${this.injector.get("EP_VENTA_RAPIDA")}`;
  }
  constructor(private injector: Injector) { }
}
