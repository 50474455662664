import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'app/services/auth/auth.service';
import { SharedService } from 'app/services/shared.service';
import { SweetAlertMsgService } from 'app/services/sweet-alert-msg.service';

export interface DialogData {

}


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent implements OnInit {
  PasswordForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private _shared: SharedService,
    private _msg: SweetAlertMsgService
  ) { }

  ngOnInit(): void {
    this.PasswordForm = this._formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.maxLength(99),
        Validators.minLength(8)
      ]],
      password2: ['', [
        Validators.required,
        Validators.maxLength(99),
        Validators.minLength(8)
      ]],
      password3: ['', [
        Validators.required,
        Validators.maxLength(99),
        Validators.minLength(8)
      ]]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  preCambiar() {
    if (this.PasswordForm.invalid) {
      return false;
    }
    let info = this.PasswordForm.value;
    if (info.password2 !== info.password3) {
      this._msg.alertWarning("Atención", "Las contraseñas debe ser identicas.");
      return false;
    }
    this.cambiarPassword();
  }
  cambiarPassword() {
    let info = this.PasswordForm.value;
    this._shared.cargando = true;
    this._auth.changePassword(info.password, info.password3).subscribe({
      next: data => {
        if (data.code !== undefined) {
          this._auth.evaluarMensajesCognito(data, "", "changePassword");
        } else {
          this.cerrar();
          this._msg.alertSuccess("Éxito", "La contraseña se ha cambiado exitosamente.");
        }
        this._shared.cargando = false;
      },
      error: data => {
        this._shared.cargando = false;
        console.log(data);
      }
    });
  }
  cerrar() {
    this.dialogRef.close();
  }
}
