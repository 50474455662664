<router-outlet></router-outlet>
<div *ngIf="_shared?.cargando" fxFlex="100">
    <div class="modalOn" fxLayout="column" fxLayoutAlign="end center" fxFlex="100">
        <div class="loadings" fxLayout="column" fxLayoutAlign="end center" fxFlex="100">
            <figure fxLayout="column" class="spinner-container">
                <img src="assets/images/logo/logo-spin.png" class="img">
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            </figure>
            <!-- <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div> -->
        </div>
    </div>
</div>