import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule  } from '@angular/material/form-field';

import { MatMenuModule } from '@angular/material/menu';
import { ChangePasswordComponent } from './change-password.component';
import { SharedModule } from 'app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';


@NgModule({
    declarations: [
        ChangePasswordComponent
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        BrowserModule, 
        FormsModule, 
        MatMenuModule,
        SharedModule
    ],
    exports     : [
        ChangePasswordComponent
    ],
    entryComponents: [
        ChangePasswordComponent
    ]
})
export class changePasswordModule
{
}
