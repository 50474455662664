import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from './services/auth/auth.guard';
import { NoAuthGuard } from './services/auth/noAuth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {
        path: '',
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        pathMatch: 'full', redirectTo: 'inicio'
    },
    // // Redirect empty path to '/example'
    // {
    //     path: '',
    //     // canActivate: [NoAuthGuard],
    //     // canActivateChild: [NoAuthGuard],
    //     pathMatch: 'full', redirectTo: 'inicio'
    // },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'reservacion' },


    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        // data: {
        //     layout: 'empty'
        // },
        children: [
            { path: 'confirmation', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },

        ]
    },

    // // Auth routes for authenticated users
    // {
    //     path: '',
    //     canActivate: [AuthGuard],
    //     canActivateChild: [AuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
    //         {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
    //     ]
    // },
    {
        path: 'cancel',
        component: LayoutComponent,
        loadChildren: () => import('app/modules/cancelaciones/cancelaciones.module').then(
            module => module.CancelacionesModule),
        // canActivate: [AuthGuard]
    },
    // Landing routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
            { path: '', loadChildren: () => import('app/modules/content/content.module').then(m => m.ContentModule) },
        ],
    },
    {
        path: '',
        canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'inicio', loadChildren: () => import('app/modules/inicio/inicio/inicio.module').then(m => m.InicioModuleModule) },
        ]
    },
    // Admin routes
    {
        path: '',
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'facturar', loadChildren: () => import('app/modules/inicio/facturar/facturar.module').then(m => m.FacturarModule) },
            { path: 'solicitar', loadChildren: () => import('app/modules/inicio/solicitar-viaje/solicitar_viaje.module').then(m => m.SolicitarViajeModule) },
            // { path: 'cancel', loadChildren: () => import('app/modules/cancelaciones/account/account.module').then(m => m.AccountModule) },
        ]
    },
    { path: '**', redirectTo: 'inicio', pathMatch: 'full' },
];
